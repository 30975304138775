import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    '42f2cc18-2bdc-4137-9ff1-59f6ace05015': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'f0ad39af-088b-451a-a187-43aa8602e50f': {
      // external-warehouse TEST
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    'bb0d19b5-31ed-452a-a9b3-658fda2b8bd4': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'a5093f0c-0995-46db-bd1e-d6d8730cbe71': {
      // external-warehouse ACCT
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    'b1ce875b-242d-44a7-b773-879446b3beed': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    'c16df4a6-7473-4981-b859-6aa664fa3da7': {
      // external-warehouse PROD
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '14+',
  },
})
