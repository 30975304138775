import getConfig from 'next/config'

import { Types as AuthTypes } from '@grandvisionhq/auth'
import type { Context as CartContext } from '@grandvisionhq/carts-v2'
import type { CheckoutSettings, Routes, ShippingMethodsLogos } from '@grandvisionhq/checkout-v2'
import { Util as StoreUtils } from '@grandvisionhq/stores-v2'

interface SettingsInput {
  cart: CartContext.CartContextModel['cart']
  shippingMethodsLogos?: ShippingMethodsLogos
}

const { publicRuntimeConfig } = getConfig()
const { orderTimeOut } = publicRuntimeConfig.checkout

// TODO: make these pathes Danish instead of Swedish
const basePath = '/checkout'
export const routes: Routes = {
  shipping: {
    label: 'Levering',
    path: `${basePath}/levering`,
  },
  overview: {
    label: 'Oversigt',
    path: `${basePath}/oversigt`,
  },
  payment: {
    label: 'Betaling',
    path: `${basePath}/betaling`,
  },
}

export const getCheckoutConfig = ({
  cart,
  shippingMethodsLogos,
}: SettingsInput): CheckoutSettings => {
  const optIns: AuthTypes.OptInItem[] = [
    {
      titleKey: 'account.optIn.marketingEmails',
      value: 'marketingEmailsOptIn',
    },
  ]

  return {
    account: {
      implicitAccountCreation: false,
      createAccountRequired: false,
    },
    consent: {
      implicitTermsAndConditions: true,
      optIns,
    },
    pickupPoints: {
      // Show additional city input field to query pickup points (default: false)
      showCityField: true,
    },
    shipping: {
      // Enforce an explicit shipping method selection (default: false)
      explicitShippingMethod: true,
    },
    shippingDetails: {
      loadingFinished: Boolean(cart) || cart === null,
      ...(cart?.billingAddress ? { billingAddress: cart.billingAddress } : {}),
      ...(cart?.shippingAddress ? { deliveryAddress: cart.shippingAddress } : {}),
      ...(cart?.customerDetails ? { customer: cart.customerDetails } : {}),
    },
    shippingMethodsLogos: shippingMethodsLogos ? shippingMethodsLogos : {},
    shippingFormFields: {
      billingAddress: [
        'firstName',
        'lastName',
        'streetName',
        'additionalAddressInfo',
        'postalCode',
        'city',
      ],
      contactDetails: ['email', 'phone'],
    },
    storeFinder: {
      showUseMyLocation: true,
      searchWhitelist: ['storeName', 'postalCode', 'streetName', 'town'] as StoreUtils.SearchKey[],
    },
    ...(orderTimeOut ? { orderCreationTimeOutLimit: orderTimeOut } : {}),
    features: {
      isCompanyEnabled: false,
      isServiceStoreEnabled: false,
    },
    login: {
      disableSignup: true,
      returnPath: cart ? `${routes.shipping?.path}?cartId=${cart.id}` : routes.shipping?.path,
    },
    routes,
    payments: {
      applePay: {
        merchantName: 'Smarteyes',
      },
    },
    logoVariant: 'main',
  }
}
