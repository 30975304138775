import type { FormatterMap, ProductRoutesOptions, RouteList } from '@grandvisionhq/frk-routing'
import { createSimpleFormatter } from '@grandvisionhq/frk-routing'
import {
  createAuthFormatters,
  createCheckoutFormatters,
  createMyAccountFormatters,
  productDetailFormatters,
  productListFormatter,
} from '@grandvisionhq/www-next/formatters'

const productRoutesOptions: ProductRoutesOptions = {
  accessories: 'briller/brillerens',
  'contact-lenses': 'kontaktlinser',
  frames: 'briller',
  'ready-readers': 'laesebriller',
  solutions: 'kontaktlinser/kontaktlinsevaesker',
  sunglasses: 'solbriller',
}

export const localRouteFormatters: FormatterMap = {
  home: createSimpleFormatter('/'),
  ...createAuthFormatters(),
  'cart.overview': createSimpleFormatter('cart'),
  'appointments.book': createSimpleFormatter('bestil-tid'),
  'appointments.cancel': createSimpleFormatter('cancel-appointment'),
  'appointments.reschedule': createSimpleFormatter('reschedule-appointment'),
  'order.confirm': createSimpleFormatter('order/confirm'),
  'express-checkout': createSimpleFormatter('express-checkout'),
  ...createCheckoutFormatters({
    path: 'checkout',
    children: { shipping: 'levering', overview: 'oversigt', payment: 'betaling' },
  }),
  ...productListFormatter(productRoutesOptions),
  ...productDetailFormatters(productRoutesOptions),
  wishlist: createSimpleFormatter('/wishlist'),
  ...createMyAccountFormatters(),
}

export const routes: RouteList = [
  ['/', 'home'],

  ['/checkout/levering', 'checkout.shipping'],
  ['/checkout/oversigt', 'checkout.overview'],
  ['/checkout/betaling', 'checkout.payment'],
  ['/api/payment-callback', 'payment.callback'],
  ['/order/confirm', 'order.confirm'],

  ['/express-checkout', 'express-checkout'],

  ['/bestil-tid/(.*)?', 'appointments.book'],
  ['/afbestil-tid', 'appointments.cancel'],
  ['/bestil-ny-tid', 'appointments.reschedule'],
]
