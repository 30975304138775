import { Countries, Currencies, DistanceUnit, IntlSettings, Locales } from '@grandvisionhq/state'

export const INTL_DEFAULTS: IntlSettings = {
  country: Countries.DK,
  locale: Locales['da-DK'],
  currency: Currencies.DKK,
  distanceUnit: DistanceUnit.km,
  dateFormat: {
    short: 'dd/MM/yyyy', // 06/03/2019
    midsize: 'dd MMMM yyyy', // 6 March 2019
    long: "eeee 'd.' d. MMM yyyy", // mandag d. 12. sep. 2022
  },
}

type PossibleLocales = keyof Pick<typeof Locales, 'da-DK'>

export const intlConfigMap: {
  [L in PossibleLocales]: IntlSettings
} = {
  'da-DK': INTL_DEFAULTS,
}
